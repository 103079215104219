:root {
  /* */
  /* A great resource to use when developing a new colour pallet for different branding version is:border: https://hypejunction.github.io/color-wizard/; */

  /* /// Colours /// */
  /* Primary */
  --colour-primary-900: #242424;
  --colour-primary-800: #404040;
  --colour-primary-700: #454545;
  --colour-primary-600: #575757;
  --colour-primary-500: #5e5e5e;
  --colour-primary-400: #757575;
  --colour-primary-300: #ababab;
  --colour-primary-200: #c4c4c4;
  --colour-primary-100: #dbdbdb;
  --colour-primary-50: #e6e6e6;

  /* Secondary */
  --colour-secondary-900: #94c11f;
  --colour-secondary-800: #9ec83a;
  --colour-secondary-700: #a9cf55;
  --colour-secondary-600: #b3d76f;
  --colour-secondary-500: #bede8a;
  --colour-secondary-400: #c8e6a5;
  --colour-secondary-300: #d3edb9;
  --colour-secondary-200: #ddf4ce;
  --colour-secondary-100: #e8fce2;
  --colour-secondary-50: #f2fffd;

  /* Warning */
  --colour-warning-900: hsl(36, 100%, 31%);
  --colour-warning-100: hsl(47, 100%, 75%);

  /* Error */
  --colour-error-700: #c42b1c;
  --colour-error-600: #d0334f;
  --colour-error-200: #e799a7;
  --colour-error-50: #faebed;

  /* Success */
  --colour-success-900: #0f7b0f;
  --colour-success-600: #44ac87;
  --colour-success-200: #dff6dd;

  /*Attention*/
  --colour-attention-700: #005fb7;
  --colour-attention-50: #f6f6f6;

  /* Normal (status: default) */
  --colour-normal-50: #bdbdbd;

  /* Uncategorised */
  --colour-white: #fff;
  --colour-black: #000;
  --colour-grey-700: hsl(0, 0%, 40%);

  /* Outline */
  --colour-outline: rgb(0 0 0 / 0.12);

  /* Icons */
  --icons: #212121;

  /* Card */
  --colour-card-200: hsl(0, 0%, 87%);
  --colour-card-100: hsl(0, 0%, 94%);
  /* /// End - Colours /// */

  /* On Surface */
  --on-surface-highEmphasis: rgb(0 0 0 / 0.87);
  --on-surface-disabled: rgb(0 0 0 / 0.27);
  --on-surface-mediumEmphasis: rgb(0 0 0 / 0.6);

  /* States - Black Overlay */
  --overlay-black-hover: rgb(0 0 0 / 0.04);
  --overlay-black-focused: rgb(0 0 0 / 0.12);
  --overlay-black-selected: rgb(0 0 0 / 0.08);

  /* Typography */
  --font-family: 'lato';
}

@import 'style/style.config.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    @apply font-sans font-normal;
  }
  body {
    @apply min-w-appMinLayout;
  }
}

@layer components {
  /*
  Instructions for adding custom tailwind css classes:

  1. Custom classes added here must be added to the custom type 'CustomTailwindCssClassnames' that is exported from '.src/style/compoundClassnames/customTailwindCssClassnames'.
  2. After adding the custom class names to the exported type, run the npm script 'generate-css-types' in order to make use of the types with the tailwindCssClassnames function with
     correct strong typing

  *Ordered alphabetically by the commented section title.
*/

  /* Body */

  .body1 {
    @apply text-base tracking-semiWider;
  }

  .body2 {
    @apply text-sm tracking-semiWide;
  }

  /* Buttons */

  .buttons {
    @apply tracking-wider font-bold leading-4 text-sm;
  }

  /* Caption */

  .caption {
    @apply font-normal text-xs tracking-nearlyWider;
  }

  /* Headlines */

  .headline1 {
    @apply font-normal text-8xl leading-tight tracking-partialTaut;
  }

  .headline2 {
    @apply font-normal text-6xl tracking-semiPartialTaut;
  }

  .headline3 {
    @apply text-5xl font-normal leading-tighter;
  }

  .headline4 {
    @apply text-4.5xl font-normal leading-tightest;
  }

  .headline5 {
    @apply font-normal text-2xl leading-none tracking-semiSlack;
  }

  .headline6 {
    @apply font-bold text-xl leading-tighter tracking-semiSlack;
  }

  /* Headers */

  .headers {
    @apply px-3 overflow-x-clip;
  }

  /* Iconds */

  .icons {
    @apply text-icons;
  }

  /* Label */

  .label {
    @apply font-bold text-sm leading-6;
  }

  /* Layout */

  .mainLayoutPadding {
    @apply px-4 sm:px-4 lg:px-8 xl:px-16 2xl:px-32 pt-8 pb-20;
  }

  .nonExpandingMainLayoutContentSection {
    @apply w-full sm:w-8/12 max-w-screen-md;
  }

  .drawerFormFullWidthOptions {
    @apply -mx-6 w-inherit;
  }

  /* NavBlockFont - (Overline - the name used in the designs, but name is a tailwind css util class name, so not possible to re-use for a custom class) */

  .navBlockFont {
    @apply text-xxs uppercase tracking-widest font-bold;
  }

  /* States */

  .overlayBlackStates {
    @apply hover:bg-overlayBlack-hover focus:bg-overlayBlack-focused;
  }

  /* Subtitles */

  .subtitle1 {
    @apply text-base tracking-slack;
  }

  .subtitle2 {
    @apply font-bold text-sm leading-6 tracking-semiSlack;
  }
}

@layer utilities {
  /* Borders */

  .borders {
    @apply border border-solid rounded;
  }
}
